import {
  primaryBold,
  primaryRegular,
  primarySemibold,
  //
  secondaryRegular,
  secondarySemibold,
  secondaryBold,
  //
  tertiaryBold,
} from "../../styles/fonts";
import Footer from "../Sections/Footer";
import Header from "../Sections/Header";

const Layout = (props) => {
  return (
    <div
      className={`${primaryRegular.variable} ${primarySemibold.variable} ${primaryBold.variable} ${secondaryRegular.variable} ${secondaryBold.variable} ${secondarySemibold.variable} ${tertiaryBold.variable}`}
    >
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
