import { useCallback, useState } from "react";
import ClickAwayListener from "react-click-away-listener";

import dynamic from "next/dynamic";
import Link from "next/link";

import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { IoMdCloseCircleOutline } from "react-icons/io";

import { staticMenu, staticMobileMenu } from "../../../data";

import style from "../../../styles/sections/Header.module.scss";

const Logo = dynamic(() => import("../../Logo"), {
  loading: () => <></>,
});

const Header = () => {
  const [menuOpen, setMenuOpen] = useState({ open: false, data: {} });
  const [mobileList, setMobileList] = useState({
    open: false,
    data: {},
  });
  const [mobileSubList, setMobileSubList] = useState({
    open: false,
    data: {},
  });

  const handleToggleMenu = useCallback(
    (item) => {
      if (!menuOpen?.open) {
        setMenuOpen({ open: true, data: item });
      } else {
        if (menuOpen?.data?.key === item.key && menuOpen?.open) {
          setMenuOpen({ open: false, data: item });
        } else {
          setMenuOpen({ open: true, data: item });
        }
      }
    },
    [menuOpen]
  );

  return (
    <div className={style.navWrap}>
      <ClickAwayListener
        onClickAway={() => {
          setMenuOpen({ open: false, data: {} });
        }}
      >
        <div className={`${style.navContainer} container-xl`}>
          <div className={style.content}>
            <Logo />
            <div className={style.actions}>
              {mobileList?.open ? (
                <IoMdCloseCircleOutline
                  className={style.actionIcon}
                  onClick={() => {
                    setMobileList({ open: false, data: staticMobileMenu });
                    setMobileSubList({ open: false });
                  }}
                />
              ) : (
                <HiOutlineMenuAlt1
                  className={style.actionIcon}
                  onClick={() => {
                    setMobileList({ open: true, data: {} });
                  }}
                />
              )}
            </div>
            <ul className={style.list}>
              {staticMenu.map((menu, key) => {
                if (menu.has_link) {
                  return (
                    <Link href={`${menu.to}`} key={key}>
                      <li key={key} className={style.listItem}>
                        <span>{menu.title}</span>
                      </li>
                    </Link>
                  );
                }
              })}
            </ul>
            <div
              className={`${style.menu} ${
                menuOpen.open ? style.open : style.close
              } `}
            >
              <div className={`${style.menuContent} container-xl`}>
                {menuOpen?.data?.list?.map((list, j) => (
                  <div className={style.menuContentItem} key={j}>
                    <h4 className={style.menuHeader}>{list.title}</h4>
                    <ul className={style.subList}>
                      {list?.sublist?.length > 0 && (
                        <>
                          {list.sublist.map((subitem, k) => (
                            <li className={style.subListItem} key={k}>
                              <span>{subitem.title}</span>
                            </li>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`${style.mobileList} ${
                mobileList?.open ? style.mobileOpen : style.mobileClose
              }`}
            >
              {mobileSubList?.open ? (
                <>
                  <div className={style.subMenuHeader}>
                    {mobileSubList?.title}
                  </div>
                </>
              ) : (
                <>
                  {staticMenu.map((menu, index) => (
                    <div key={index} className={style.mobileListContent}>
                      <div className={style.mobileListItem}>
                        <h3>
                          <Link href={`${menu.to}`}>
                            <span
                              onClick={() => {
                                setMobileList({ open: false, data: {} });
                              }}
                            >
                              {menu.title}
                            </span>
                          </Link>
                        </h3>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Header;
