import React from "react";
import { footerMenu } from "../../../data";
import style from "../../../styles/sections/Footer.module.scss";
import Logo from "../../Logo";

const Footer = () => {
  return (
    <footer className={style.footerWrap}>
      <div className={`${style.footerContainer} container-xl`}>
        <div className={style.content}>
          <div className={style.logo}>
            <Logo variant="light" />
            <div className={style.about}>
              <p>
                The easier way to find the UKs best care homes and retirement
                communities.
              </p>
            </div>
          </div>
          <div className={style.list}>
            {footerMenu.map((menu, i) => (
              <div className={style.listItem} key={i}>
                <h5 className={style.listTitle}>{menu.title}</h5>
                <ul className={style.innerList}>
                  {menu.list.map((innermenu, j) => (
                    <li className={style.innerListItem} key={j}>
                      <span>{innermenu?.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className={style.footer}>
          <p> &#169; 2023 Carefindr</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
