import React from "react";

import Image from "next/image";
import Link from "next/link";
import images from "../../assets/images";
import style from "../../styles/Logo.module.scss";

const Logo = (props) => {
  return (
    <Link href="/" legacyBehavior>
      <a className={style.logoWrap}>
        <Image
          layout="responsive"
          src={
            props?.variant === "light" ? images.logoLight.src : images.logo.src
          }
          className={`${style.logo}`}
          alt={props.alt ? props.alt : "Logo"}
          priority={"true"}
          width={100}
          height={100}
        />
      </a>
    </Link>
  );
};

export default React.memo(Logo);
